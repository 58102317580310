import React, { useEffect, useState } from 'react'
import styles from './WhereWeAre.module.css'

const locations = [
  {
    city: 'Dubai, UAE',
    timezone: 'Asia/Dubai',
    address: 'Galadari Building 17,\nProduction City'
  },
  {
    city: 'Dublin, Ireland',
    timezone: 'Europe/Dublin',
    address: '26 Pembroke Street Upper, Dublin 2,\nD02 X361, IRL.'
  },
  {
    city: 'New York, USA',
    timezone: 'America/New_York',
    address: '41st floor NYC Hub\n2 Grand Central Tower,\n140 E 45th St, NY 10017'
  }
]

const LocationCard = ({ city, time, address }) => (
  <div className={styles.locationCard}>
    <div className={styles.city}>{city}</div>
    <div className={styles.timeWrapper}>
      <div className={styles.timeLabel}>Current Time</div>
      <div className={styles.time}>{time}</div>
    </div>
    <div className={styles.addressWrapper}>
      <div className={styles.addressLabel}>Address</div>
      <div className={styles.address}>{address}</div>
    </div>
  </div>
)

const WhereWeAre = () => {
  const [times, setTimes] = useState({})

  useEffect(() => {
    const updateTimes = () => {
      const updatedTimes = {}
      locations.forEach((location) => {
        const time = new Intl.DateTimeFormat('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZone: location.timezone
        }).format(new Date())
        updatedTimes[location.city] = time
      })
      setTimes(updatedTimes)
    }

    updateTimes()
    const interval = setInterval(updateTimes, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>Where we are</h2>
        {locations.map((location, index) => (
          <LocationCard key={index} city={location.city} time={times[location.city] || 'Loading...'} address={location.address} />
        ))}
      </div>
    </div>
  )
}

export default WhereWeAre
